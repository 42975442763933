@font-face {
    font-family: 'Julius Sans One';
    src: url(/static/media/JuliusSansOne-Regular.1fce8b7c.ttf);
}

html {
  font-family: 'Julius Sans One', sans-serif;
  background-color: #F1F5F2;
  color: #5C5C60;
}

.Image:hover{
  -webkit-filter: blur(1px);
          filter: blur(1px)
}

