@font-face {
    font-family: 'Julius Sans One';
    src: url('./assets/JuliusSansOne-Regular.ttf');
}

html {
  font-family: 'Julius Sans One', sans-serif;
  background-color: #F1F5F2;
  color: #5C5C60;
}
